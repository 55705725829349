<header>

  <div class="container">

    <div class="row align-items-center">

      <div class="col-auto d-xl-none">

        <button (click)="onMenuToggleClick()" class="menu-toggle btn btn-icon" title="Меню" i18n-title>
          <mat-icon class="icon" svgIcon="sax:linear-menu"></mat-icon>
        </button>

      </div>

      <div class="logo-col col-auto">

        <a class="logo d-inline-block" routerLink="/">
          <img class="logo-image d-none d-sm-block" ngSrc="/assets/images/logo-with-label.svg" width="141" height="38"
               alt="Логотип EVLAND" i18n-alt />
          <img class="logo-image d-sm-none" ngSrc="/assets/images/logo-simple.svg" width="40" height="39" alt="Логотип EVLAND" i18n-alt />
        </a>

      </div>

      <div class="col-auto d-none d-xl-inline-flex" itemscope itemtype="http://schema.org/SiteNavigationElement">

        <ul class="nav" itemprop="about" itemscope itemtype="http://schema.org/ItemList">

          @for (navLink of navLinks; track navLink.label) {

            <li class="nav-item" itemprop="itemListElement" itemscope="" itemtype="http://schema.org/ItemList">

              <a [class.disabled]="navLink.disabled" [routerLink]="navLink.href"
                 [routerLinkActiveOptions]="{exact: navLink.href === '/'}" routerLinkActive="active" class="nav-link"
                 itemprop="url">
                {{ navLink.label }}
                <meta [content]="navLink.label" itemprop="name">
              </a>

            </li>

          }

        </ul>

      </div>

      <div class="col d-flex flex-row justify-content-end align-items-center">

        <div (click)="openCallbackModal()"
             class="consultation-link d-none d-xxl-flex flex-row justify-content-center align-items-center">
          <mat-icon class="icon me-1" svgIcon="sax:bold-call-calling"></mat-icon>
          <span class="label" i18n>Консультація</span>
        </div>

        <evland-header-search-bar></evland-header-search-bar>

        <!--        <div *ngIf="currentUser$ | async as currentUser; else signInButtonBlock" class="ms-3 d-none d-xl-flex" placement="bottom-end"-->
        <!--             ngbDropdown>-->
        <!--          <button id="userMenuToggle" class="btn btn-icon btn-auth" ngbDropdownToggle>-->
        <!--            <img *ngIf="currentUser.avatar; else noAvatarBlock" [ngSrc]="currentUser.avatar.url" width="40" height="40" [alt]="currentUser.first_name + ' ' + currentUser.last_name" class="avatar" />-->
        <!--            <ng-template #noAvatarBlock>-->
        <!--              <mat-icon class="icon" svgIcon="sax:bulk-profile-circle"></mat-icon>-->
        <!--            </ng-template>-->
        <!--          </button>-->
        <!--          <div ngbDropdownMenu >-->
        <!--            <a *ngFor="let item of userMenuLinks" [routerLink]="item.href"-->
        <!--               class="d-flex justify-content-start align-items-center" ngbDropdownItem>-->
        <!--              <mat-icon [svgIcon]="item.icon" class="icon me-2"></mat-icon>-->
        <!--              <span class="label">{{item.label}}</span>-->
        <!--            </a>-->
        <!--            <button (click)="onLogoutClick()" class="logout-button d-flex justify-content-start align-items-center" ngbDropdownItem>-->
        <!--              <mat-icon svgIcon="sax:twotone-login-1" class="icon me-2"></mat-icon>-->
        <!--              <span class="label">Вийти</span>-->
        <!--            </button>-->
        <!--          </div>-->
        <!--        </div>-->

        <!--        <ng-template #signInButtonBlock>-->

        <!--          <button (click)="openSignInModal()" class="btn btn-icon btn-auth ms-3" ngbTooltip="Увійти" i18n-ngbTooltip>-->
        <!--            <mat-icon class="icon" svgIcon="sax:bulk-profile-circle"></mat-icon>-->
        <!--          </button>-->

        <!--        </ng-template>-->

        <a routerLink="/comparison" class="btn btn-square btn-outline-secondary btn-comparison ms-3"
           ngbTooltip="Порівняння" i18n-ngbTooltip title="Порівняння" i18n-title>

          <mat-icon class="icon" svgIcon="sax:linear-chart-1"></mat-icon>

          @if (modelsForComparison$ | async; as modelsForComparison) {
            <span class="badge">{{ modelsForComparison }}</span>
          }

        </a>

      </div>

    </div>

  </div>

</header>

<ng-template #sidenavMenuContent let-modal>
  <evland-sidenav-menu (onClose)="modal.close($event)"></evland-sidenav-menu>
</ng-template>
