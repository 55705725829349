import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnDestroy } from '@angular/core';
import { NgbToast } from '@ng-bootstrap/ng-bootstrap';
import { Toast } from '@shared/models/toast';
import { ToastStoreService } from '@shared/stores/toast-store.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'evland-toasts-area',
    templateUrl: './toasts-area.component.html',
    styleUrls: ['./toasts-area.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
      AsyncPipe,
      NgbToast,
    ],
})
export class ToastsAreaComponent implements OnDestroy {
  toasts$: Observable<Toast[]>;

  #toastStore = inject(ToastStoreService);

  constructor() {
    this.toasts$ = this.#toastStore.list$;
  }

  ngOnDestroy(): void {
    this.#toastStore.clear();
  }

  removeToast(toast: Toast): void {
    this.#toastStore.removeFromList(toast.id);
  }
}
