import { inject, NgModule } from '@angular/core';
import { ApolloClientOptions, InMemoryCache, split } from '@apollo/client/core';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { environment } from '@env/environment';
import { provideApollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { Kind, OperationTypeNode } from 'graphql';
import { createClient } from 'graphql-ws';
import { WebSocket } from 'ws';

export function createApolloWithWs(): ApolloClientOptions<any> {
  const httpLink = inject(HttpLink);
  const http = httpLink.create({ uri: `${environment.apiUrl}/graphql` });

  let ws;

  try {
    ws = new GraphQLWsLink(createClient({ url: environment.wsUrl }));
  } catch {
    ws = new GraphQLWsLink(createClient({ url: environment.wsUrl, webSocketImpl: WebSocket }));
  }

  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query);

      return (
        definition.kind === Kind.OPERATION_DEFINITION &&
        definition.operation === OperationTypeNode.SUBSCRIPTION
      );
    },
    ws,
    http,
  );

  return {
    link,
    cache: new InMemoryCache(),
  };
}

// export function createSimpleApollo(): ApolloClientOptions<any> {
//   const httpLink = inject(HttpLink);
//
//   return {s
//     link: httpLink.create({ uri: `${environment.apiUrl}/graphql` }),
//     cache: new InMemoryCache(),
//   };
// }

@NgModule({
  providers: [ provideApollo(createApolloWithWs) ],
})
export class GraphQLModule {
}

