<div class="wrapper">

  <div class="container">

    <div class="row">

      <div class="col-12 col-lg-6">

        <div class="chat-item d-flex justify-content-start align-items-center">

          <img [useSrcset]="true" class="image me-3"
               lazyLoad="/assets/images/footer-chats/telegram.webp 1x, /assets/images/footer-chats/telegram@2x.webp 2x, /assets/images/footer-chats/telegram@3x.webp 3x"
               alt="Логотип Telegram" i18n />

          <div>

            <h3 i18n>Підпишіться на Telegram</h3>

            <p i18n>Обмінюйтеся думками, порадами та досвідом з іншими
              ентузіастами. Створіть з нами майбутнє чистої мобільності!</p>

            <a href="https://t.me/chademoforchina" target="_blank" class="btn btn-telegram d-inline-flex flex-row justify-content-center align-items-center">
              <mat-icon class="icon me-2" svgIcon="social:telegram-inverted"></mat-icon>
              <span i18n>Підписатися</span>
            </a>

          </div>

        </div>

      </div>

      <div class="col-12 col-lg-6">

        <div class="chat-item d-flex justify-content-start align-items-center">

          <img [useSrcset]="true" class="image me-3"
               lazyLoad="/assets/images/footer-chats/viber.webp 1x, /assets/images/footer-chats/viber@2x.webp 2x, /assets/images/footer-chats/viber@3x.webp 3x"
               alt="Логотип Viber" i18n />


          <div>

            <h3 i18n>Підпишіться на Viber!</h3>

            <p i18n>Дізнайся про надходження нових моделей автівок та вигідних пропозицій від наших партнерів першим.</p>

            <a href="https://invite.viber.com/?g2=AQAxXbbRQOHyE1Jnn5nskiaUR91VtNzgMBbLcOHkK7QC4ckyMo5NiPq7faLI%2Fq1r" target="_blank" class="btn btn-viber d-inline-flex flex-row justify-content-center align-items-center">
              <mat-icon class="icon me-2" svgIcon="social:viber"></mat-icon>
              <span i18n>Підписатися</span>
            </a>

          </div>

        </div>

      </div>

    </div>

  </div>

</div>
