<div class="header d-flex flex-row justify-content-between align-items-center">
  <a (click)="close()" class="logo d-inline-block" routerLink="/">
    <img class="logo-image" ngSrc="/assets/images/logo-with-label.svg" width="141" height="38" alt="Логотип EVLAND" i18n-alt />
  </a>
  <button (click)="close()" class="btn btn-sm btn-icon close-button ms-3">
    <mat-icon svgIcon="sax:linear-add"></mat-icon>
  </button>
</div>

<div class="content">

<!--  <div class="auth-block d-flex flex-row align-items-start">-->
<!--    <mat-icon class="profile-icon me-2" svgIcon="sax:linear-profile-circle"></mat-icon>-->
<!--    <div class="content">-->
<!--      <ul class="list-inline">-->
<!--        <li class="list-inline-item">-->
<!--          <a i18n>Вхід</a>-->
<!--        </li>-->
<!--        <li class="list-inline-item">-->
<!--          <a i18n>Реєстрація</a>-->
<!--        </li>-->
<!--      </ul>-->
<!--      <div class="hint" i18n>Авторизуйтесь для отримання розширених можливостей</div>-->
<!--    </div>-->
<!--  </div>-->

  <ul class="nav flex-column">

    @for (navLink of navLinks; track navLink.label) {

      <li class="nav-item">

        <a (click)="close()" [class.disabled]="navLink.disabled" [routerLink]="navLink.href"
           [routerLinkActiveOptions]="{exact: navLink.href === '/'}" routerLinkActive="active"
           class="nav-link d-flex flex-row align-items-center">

          <mat-icon [svgIcon]="navLink.icon" class="icon me-2"></mat-icon>

          <div class="label d-inline-block">{{ navLink.label }}</div>

        </a>

      </li>

    }

  </ul>

  <div class="helping-hand d-flex flex-row justify-content-between align-items-center">
    <div class="label d-inline-block" i18n>
      Допомога
    </div>
    <button (click)="openCallbackModal()" class="consultation-button btn btn-sm btn-primary ms-3" i18n>
      Консультація
    </button>
  </div>

  <div class="chats text-center">
    <h3 class="title mb-2" i18n>Приєднуйтесь до нашої спільноти</h3>

    <div class="d-flex flex-column">

      <a href="https://t.me/chademoforchina" target="_blank"
         class="btn btn-telegram d-inline-flex flex-row justify-content-center align-items-center mb-2">
        <mat-icon class="icon me-2" svgIcon="social:telegram-inverted"></mat-icon>
        <span i18n>Telegram</span>
      </a>

      <a href="https://invite.viber.com/?g2=AQAxXbbRQOHyE1Jnn5nskiaUR91VtNzgMBbLcOHkK7QC4ckyMo5NiPq7faLI%2Fq1r"
         target="_blank" class="btn btn-viber d-inline-flex flex-row justify-content-center align-items-center">
        <mat-icon class="icon me-2" svgIcon="social:viber"></mat-icon>
        <span i18n>Viber</span>
      </a>

    </div>

  </div>

</div>
