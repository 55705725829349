import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/routes'),
  },
  {
    path: 'catalog',
    loadChildren: () => import('./catalog/routes'),
  },
  {
    path: 'offers',
    loadChildren: () => import('./offers/routes'),
  },
  {
    path: 'financing',
    loadChildren: () => import('./financing/routes'),
  },
  {
    path: 'about-us',
    loadChildren: () => import('./about-us/routes'),
  },
  {
    path: 'euro-charge',
    loadChildren: () => import('./euro-charge/routes'),
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./contact-us/routes'),
  },
  // {
  //   path: 'account',
  //   loadChildren: () => import('./account/routes'),
  // },
  {
    path: 'comparison',
    loadChildren: () => import('./comparison/routes'),
  },
  {
    path: 'public-offer',
    loadChildren: () => import('./public-offer/routes'),
  },
  {
    path: 'terms-and-conditions',
    loadChildren: () => import('./terms-and-conditions/routes'),
  },
  {
    path: 'search',
    loadChildren: () => import('./search/routes'),
  },
  {
    path: '404',
    loadChildren: () => import('./not-found/routes'),
  },
  {
    path: '**',
    redirectTo: '404',
  },
];
