import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { LazyLoadImageModule } from 'ng-lazyload-image';

@Component({
    selector: 'evland-footer-chats',
    templateUrl: './footer-chats.component.html',
    styleUrls: ['./footer-chats.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
      MatIcon,
      LazyLoadImageModule,
    ]
})
export class FooterChatsComponent {}
