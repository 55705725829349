import { AsyncPipe, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, inject, ViewChild } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { SearchBarComponent } from '@app/core/header/search-bar/search-bar.component';
import { SidenavMenuComponent } from '@app/core/sidenav-menu/sidenav-menu.component';
import { NgbModal, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { CallbackType } from '@shared/models/callback-type';
import { NavLink } from '@shared/models/nav-link';
import { CallbackStoreService } from '@shared/stores/callback-store.service';
import { ComparisonStoreService } from '@shared/stores/comparison-store.service';
import { map, Observable, take } from 'rxjs';

// type AuthDismissReason = 'signIn' | 'signUp' | 'forgotPassword';

@Component({
    selector: 'evland-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
  imports: [
    AsyncPipe,
    RouterLink,
    RouterLinkActive,
    NgOptimizedImage,
    MatIcon,
    NgbTooltip,
    SearchBarComponent,
    SidenavMenuComponent,
  ]
})
export class HeaderComponent {
  @ViewChild('sidenavMenuContent') sidenavMenuContent: ElementRef;

  navLinks: NavLink[] = [
    { href: '/', label: $localize`Головна` },
    { href: '/catalog', label: $localize`Каталог` },
    { href: '/offers', label: $localize`Із пробігом` },
    { href: '/about-us', label: $localize`Про компанію` },
    { href: '/financing', label: $localize`Фінансування` },
    { href: '/contact-us', label: $localize`Контакти` },
  ];
  // userMenuLinks: NavLinkWithIcon[] = [
  //   { href: '/account/personal', icon: 'sax:outline-user', label: $localize`Особиста інформація` },
  //   { href: '/account/favorite', icon: 'sax:twotone-heart', label: $localize`Вибране` },
  //   { href: '/account/view-history', icon: 'sax:twotone-eye', label: $localize`Переглянуті авто` },
  //   { href: '/account/payment', icon: 'sax:twotone-wallet', label: $localize`Платіжна інформація` },
  //   { href: '/account/support', icon: 'sax:twotone-message-question', label: $localize`Запитання та допомога` },
  // ];
  // currentUser$: Observable<User>;
  modelsForComparison$: Observable<number>;

  // #authService = inject(AuthService);
  #callbackStore = inject(CallbackStoreService);
  #comparisonStore = inject(ComparisonStoreService);
  // #cdr = inject(ChangeDetectorRef);
  // #jwtService = inject(JwtService);
  #modalService = inject(NgbModal);
  // #userStore = inject(UserStoreService);

  constructor() {
    this.modelsForComparison$ = this.#comparisonStore.list$.pipe(map((ids: string[]) => ids.length));
    // this.currentUser$ = this.#userStore.current$;

    // this.fetchCurrentUser();
  }

  // onLogoutClick(): void {
  //   this.#authService.logout();
  // }

  onMenuToggleClick() {
    this.#modalService.open(
      this.sidenavMenuContent,
      {
        ariaLabelledBy: 'modal-basic-title',
        fullscreen: true,
        windowClass: 'sidenav-menu'
      },
    );
  }

  openCallbackModal(): void {
    this.#callbackStore.show({
      type: CallbackType.Consultation,
      title: $localize `Замовити консультацію`,
      actionLabel: $localize`Замовити`,
      subject: $localize`Консультація`,
    });
  }

  // openSignInModal(): void {
  //   const modal = this.#modalService.open(SignInModalComponent);
  //
  //   modal
  //     .dismissed
  //     .pipe(
  //       take(1),
  //     )
  //     .subscribe((reason: AuthDismissReason) => this.onAuthModalDismiss(reason));
  // }

  // private fetchCurrentUser(): void {
  //   if (!this.#jwtService.getToken()) return;
  //
  //   this.#authService.me()
  //     .subscribe((user: User) => {
  //       this.#userStore.current = user;
  //
  //       this.#cdr.markForCheck();
  //     })
  // }

  // private onAuthModalDismiss(reason: AuthDismissReason): void {
  //   if (!reason) return;
  //
  //   const nextModal = {
  //     signIn: SignInModalComponent,
  //     signUp: SignUpModalComponent,
  //     forgotPassword: ForgotPasswordModalComponent,
  //   };
  //
  //   const modal = this.#modalService.open(nextModal[reason]);
  //
  //   modal
  //     .dismissed
  //     .pipe(
  //       take(1),
  //     )
  //     .subscribe((reason: AuthDismissReason) => this.onAuthModalDismiss(reason));
  // }
}
