<div class="search-control-wrapper" placement="bottom-end" ngbDropdown>

  <form (ngSubmit)="onSubmit()" [formGroup]="form"
        class="search-control justify-content-center align-items-center ms-xl-3">

    <div class="input-group">

      <input (focus)="onInputFocus()" formControlName="query" class="form-control" placeholder="Пошук авто"
             i18n-placeholder />

      <button class="btn btn-outline-primary" type="submit">
        <mat-icon [svgIcon]="isSearching ? 'sax:twotone-refresh-2' : 'sax:linear-search-normal-custom'"
                  [class.rotate]="isSearching" class="icon"></mat-icon>
      </button>

    </div>

  </form>

  @if (queryControl.value && searchResults && showSearch && !isSearching) {

    <div class="search-dropdown" ngbDropdownMenu>

      @if (!!searchResults.brands.length || searchResults.series.length) {

        @if (!!searchResults.brands.length) {
          <div class="results-group-title" i18n>Бренди</div>
        }

        @for (brand of searchResults.brands; track brand.id) {

          <a (click)="clearSearch()" [queryParams]="{ brand_id: brand.id }"
             routerLink="/catalog"
             class="brand-result d-flex justify-content-start align-items-center" ngbDropdownItem>

            <img [lazyLoad]="brand.logo.url" class="logo me-2" defaultImage="/assets/images/logo-placeholder.svg" />

            <div class="name flex-grow-1 me-3">{{ brand.name }}</div>

            <div class="more d-flex justify-content-end align-items-center">

              <span class="label d-none d-sm-inline-flex me-1">Усі моделі</span>

              <mat-icon class="icon" svgIcon="sax:linear-arrow-right"></mat-icon>

            </div>

          </a>

        }

        @if (!!searchResults.series.length) {
          <div class="results-group-title" i18n>Моделі</div>
        }

        @for (series of searchResults.series; track series.id) {

          <a (click)="clearSearch()" [routerLink]="['/catalog', series.id]" class="series-result d-flex justify-content-start align-items-center" ngbDropdownItem>

            <img [lazyLoad]="series.preview.url" class="preview me-2"
                 defaultImage="/assets/images/car-image-placeholder.svg" />

            <div class="name flex-grow-1 me-3">{{ series.brand.name }} {{ series.name }}</div>

            <mat-icon class="more-icon" svgIcon="sax:linear-arrow-right"></mat-icon>

          </a>

        }

      } @else {

        <div class="no-results text-center flex-column justify-content-center align-items-center">

          <mat-icon class="icon mb-3" svgIcon="sax:twotone-warning-2"></mat-icon>

          <div class="label">Нічого не знайдено</div>

        </div>

      }

    </div>

  }

</div>
