import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'evland-cookie-confirmation',
  templateUrl: './cookie-confirmation.component.html',
  styleUrls: [ './cookie-confirmation.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [

  ],
})
export class CookieConfirmationComponent {

}
