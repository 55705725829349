import { DOCUMENT, isPlatformServer } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Inject, OnDestroy, PLATFORM_ID } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { CookieConfirmationComponent } from '@app/core/cookie-confirmation/cookie-confirmation.component';
import { FooterChatsComponent } from '@app/core/footer-chats/footer-chats.component';
import { FooterComponent } from '@app/core/footer/footer.component';
import { HeaderComponent } from '@app/core/header/header.component';
import { ToastsAreaComponent } from '@app/core/toasts-area/toasts-area.component';
import { SeoService } from '@shared/services/seo.service';
import { filter, Subject, takeUntil, tap } from 'rxjs';

// const ACCESS_TOKEN_COOKIE_NAME = 'access_token';

@Component({
  selector: 'evland-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    RouterOutlet,
    HeaderComponent,
    ToastsAreaComponent,
    FooterChatsComponent,
    FooterComponent,
    CookieConfirmationComponent,
  ],
})
export class AppComponent implements OnDestroy {
  // #authService = inject(AuthService);
  // #cookieService = inject(CookieService);
  #matIconRegistry = inject(MatIconRegistry);
  // #jwtService = inject(JwtService);
  #router = inject(Router);
  #sanitizer = inject(DomSanitizer);
  #seoService = inject(SeoService);
  // #toastStore = inject(ToastStoreService);

  private canonicalUrlEl: HTMLLinkElement;

  private readonly destroyed$ = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: string,
  ) {
    this.initIcons();

    if (isPlatformServer(this.platformId)) {
      return;
    }

    // this.checkCookies();
    this.initCanonicalUrl();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  // private checkCookies(): void {
  //   const accessTokenCookie = this.#cookieService.get(ACCESS_TOKEN_COOKIE_NAME);
  //
  //   if (!accessTokenCookie) {
  //     return;
  //   }
  //
  //   if (accessTokenCookie === 'oauth_failed') {
  //     this.#toastStore.show({
  //       text: $localize`Користувач не знайдено, перевірте обліковий запис або зареєструйтесь`,
  //       type: ToastType.Error,
  //     });
  //
  //     this.#cookieService.delete(ACCESS_TOKEN_COOKIE_NAME);
  //   } else {
  //     const oldToken = this.#jwtService.getToken();
  //     const updateUser = !oldToken || oldToken !== accessTokenCookie;
  //
  //     if (!updateUser) {
  //       return;
  //     }
  //
  //     this.#jwtService.saveToken(accessTokenCookie);
  //     this.#authService.me();
  //   }
  // }

  private initCanonicalUrl(): void {
    this.#router.events
      .pipe(
        takeUntil(this.destroyed$),
        filter(event => event instanceof NavigationEnd),
        tap(() => {
          if (this.canonicalUrlEl) {
            this.canonicalUrlEl.remove();
          }
        }),
        filter(() => window.location.href.includes('?')),
      )
      .subscribe(() => this.canonicalUrlEl = this.#seoService.createCanonicalUrl(window.location.href.split('?')[0]));
  }

  private initIcons(): void {
    const domain = (isPlatformServer(this.platformId)) ? 'http://localhost:4000/' : '';
    const customIconsUrl = this.#sanitizer.bypassSecurityTrustResourceUrl(`${domain}assets/icons/custom-icons.svg`);
    const iconlyIconsUrl = this.#sanitizer.bypassSecurityTrustResourceUrl(`${domain}assets/icons/iconly-icons.svg`);
    const saxIconsUrl = this.#sanitizer.bypassSecurityTrustResourceUrl(`${domain}assets/icons/sax-icons.svg`);
    const socialIconsUrl = this.#sanitizer.bypassSecurityTrustResourceUrl(`${domain}assets/icons/social-icons.svg`);

    this.#matIconRegistry.addSvgIconSetInNamespace('custom', customIconsUrl);
    this.#matIconRegistry.addSvgIconSetInNamespace('iconly', iconlyIconsUrl);
    this.#matIconRegistry.addSvgIconSetInNamespace('sax', saxIconsUrl);
    this.#matIconRegistry.addSvgIconSetInNamespace('social', socialIconsUrl);
  }
}

