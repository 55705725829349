<div class="toasts-area">

  @for (toast of toasts$ | async; track toast.id) {
    <ngb-toast
      (hidden)="removeToast(toast)"
      [autohide]="true"
      [delay]="toast.delay || 5000"
      [class]="'toast-container type-' + toast.type"
    >
      {{ toast.text }}
    </ngb-toast>
  }

</div>
