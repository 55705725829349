import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withFetch,
  withInterceptorsFromDi
} from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom, isDevMode } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withComponentInputBinding, withInMemoryScrolling } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ErrorHandlerInterceptor } from '@app/core/interceptors/error-handler.interceptor';
import { GraphQLModule } from '@app/graphql.module';
import { NgxSsrCacheModule } from '@ngx-ssr/cache';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { routes } from './app.routes';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withComponentInputBinding(), withInMemoryScrolling({ scrollPositionRestoration: 'top' })),
    provideClientHydration(),
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    provideAnimations(),
    importProvidersFrom(BrowserModule),
    importProvidersFrom(NgxSsrCacheModule.configLruCache({ maxAge: 5 * 60000, maxSize: 5 })),
    importProvidersFrom(LazyLoadImageModule),
    importProvidersFrom(GraphQLModule),
    importProvidersFrom(ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })),
    // {
    //     provide: HTTP_INTERCEPTORS,
    //     useClass: AuthInterceptor,
    //     multi: true,
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
  ]
}
